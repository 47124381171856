<template>
  <div class="text-center">
    <v-dialog style="z-index: 20006" persistent v-model="modal" width="500">
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Filtre</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-form>
            <v-card-title class="mt-0"></v-card-title>
            <v-row>
              <v-select
                :items="sinav_turuleri"
                label="Sınav türü"
                itemText="kisaAd"
                itemValue="id"
                @change="secildi"
              >
              </v-select>
            </v-row>
            <v-row>
              <v-select
                v-model="secilen_sinav_sayisi"
                :items="sinav_sayisi"
                label="Son kaç sınav"
              >
              </v-select>
            </v-row>
          </v-form>
          <v-row class="mt-5">
            <v-spacer> </v-spacer>
            <v-btn
              class="mr-2"
              dark
              @click="$emit('hideDialog', null, null)"
              color="red"
            >
              Vazgeç
            </v-btn>
            <v-btn dark @click="hazirla" color="primary"> Tamam </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { sinav_turleri_listesi } from "@/query/olc_sinavturleri";
export default {
  props: {
    modal: null,
    gelen_id: null,
  },
  data() {
    return {
      sinav_sayisi: [5, 10],
      sinav_turuleri: [],
      secilen_sinav_sayisi: null,
      secilen_sinav_turu: null,
    };
  },
  async mounted() {
    this.sinav_turuleri = await sinav_turleri_listesi();
    this.sinav_turuleri =
      this.sinav_turuleri.veriler?.sonuc?.sinavTurleri.filter(
        (v) => v.kisaAd === "TYT" || v.kisaAd === "AYT" || v.kisaAd === "LGS"
      );
  },

  methods: {
    hatali(gelen_mesaj) {
      this.$gizmo().ac({
        aciklama: gelen_mesaj,
        baslik: "Dikkat",
        icon: "dikkat",
        hayir: false,
      });
    },
    hazirla() {
      this.$emit(
        "hideDialog",
        this.secilen_sinav_sayisi,
        this.secilen_sinav_turu
      );
    },
    secildi(i) {
      this.secilen_sinav_turu = i;
    },
  },
  components: {},
};
</script>

Datepicker
